<template>
  <validation-observer ref="adminAspectsForm">
    <b-form
      @submit.prevent="validationForm"
    >
      <b-card>
        <b-col>
          <b-col>
            <b-row class="justify-content-center my-2">
              <h4>GERENCIA Y PRINCIPALES EJECUTIVOS</h4>
            </b-row>
          </b-col>
          <!-- Row Loop 1-->
          <div>
            <b-row
              v-for="(executive, index) in executives"
              :id="index"
              :key="index"
              class="container-border py-1 px-1 my-2"
            >
              <b-col
                sm="12"
                style="text-align: center"
              >
                <h5 class="mb-3 mt-2">
                  GERENTE O EJECUTIVO N° {{ index+1 }}
                </h5>
              </b-col>
              <!-- executiveFullName -->
              <b-col
                md="4"
              >
                <b-form-group
                  label="Nombre completo*"
                  :label-for="`${index}executiveFullName`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nombre completo"
                    rules="max:100"
                    :vid="`${index}executiveFullName`"
                  >
                    <b-form-input
                      :id="`${index}executiveFullName`"
                      v-model="executive.executiveFullName"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Juan Perez"
                      :readonly="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- executivePosition -->
              <b-col
                lg="3"
                md="3"
              >
                <b-form-group
                  label="Cargo*"
                  :label-for="`${index}executivePosition`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cargo"
                    rules="required"
                    :vid="`${index}executivePosition`"
                  >
                    <v-select
                      :id="`${index}executivePosition`"
                      v-model="executive.executivePosition"
                      label="cargo"
                      :options="optionsPositions"
                      :name="`${index}executivePosition`"
                      :disabled="!canEdit"
                    >
                      <span slot="no-options">
                        Lo sentimos, no hay opciones que coincidan.
                      </span>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- executiveTypeDocument -->
              <b-col
                lg="2"
                md="4"
              >
                <b-form-group
                  label="Tipo de documento*"
                  :label-for="`${index}executiveTypeDocument`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tipo de documento"
                    :vid="`${index}executiveTypeDocument`"
                  >
                    <b-form-select
                      :id="`${index}executiveTypeDocument`"
                      v-model="executive.executiveTypeDocument"
                      type="text"
                      :options="optionsTypeDocument"
                      :state="errors.length > 0 ? false:null"
                      name="typeDocument"
                      :disabled="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- executiveNumberDocument -->
              <b-col
                lg="3"
                md="4"
              >
                <b-form-group
                  label="Número de documento*"
                  :label-for="`${index}executiveNumberDocument`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="número de documento"
                    :rules="`document:${executives[index].executiveTypeDocument}`"
                    :vid="`${index}executiveNumberDocument`"
                  >
                    <b-form-input
                      :id="`${index}executiveNumberDocument`"
                      v-model="executive.executiveNumberDocument"
                      :disabled="executive.executiveTypeDocument ? false:true"
                      :state="errors.length > 0 ? false:null"
                      name="numberDocument"
                      :readonly="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- executiveTelephone -->
              <b-col
                lg="4"
                md="3"
              >
                <b-form-group
                  label="Teléfono*"
                  :label-for="`${index}executiveTelephone`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Teléfono"
                    rules="min:9|max:20"
                    :vid="`${index}executiveTelephone`"
                  >
                    <b-form-input
                      :id="`${index}executiveTelephone`"
                      v-model="executive.executiveTelephone"
                      :state="errors.length > 0 ? false:null"
                      :formatter="formatterTel"
                      name="tel"
                      placeholder="johndoe"
                      :readonly="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- executiveEmail -->
              <b-col
                md="4"
              >
                <b-form-group
                  label="Correo electrónico*"
                  :label-for="`${index}executiveEmail`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="correo electrónico"
                    rules="email"
                    :vid="`${index}executiveEmail`"
                  >
                    <b-form-input
                      :id="`${index}executiveEmail`"
                      v-model="executive.executiveEmail"
                      :state="errors.length > 0 ? false:null"
                      name="email"
                      placeholder="john@example.com"
                      :readonly="!canEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              class="justify-content-end"
            >
              <!-- buttons -->
              <b-col md="6">
                <b-row>
                  <b-col class="mb-50">
                    <b-row class="justify-content-center">
                      <b-button
                        variant="primary"
                        class="m-1 mt-md-2"
                        :disabled="!canEdit"
                        @click="addExecutive(index)"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span class="align-middle">Añadir nuevo</span>
                      </b-button>
                    </b-row>
                  </b-col>
                  <b-col
                    v-if="executives.length>1"
                    class="mb-50"
                  >
                    <b-row class="justify-content-center">
                      <b-button
                        variant="outline-danger"
                        class="m-1 mt-md-2"
                        :disabled="!canEdit"
                        @click="removeExecutive(index)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-25"
                        />
                        <span class="align-middle">Eliminar</span>
                      </b-button>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-card>
      <b-card>
        <b-col>
          <b-col>
            <b-row class="justify-content-center my-2">
              <h4>ACCIONISTAS Y/O REPRESENTANTES</h4>
            </b-row>
          </b-col>
          <div>
            <b-row
              v-for="(partner, index) in partners"
              :id="index"
              :key="index"
              class="container-border py-1 px-1 my-2"
            >
              <b-col
                sm="12"
                style="text-align: center"
              >
                <h5 class="mb-3 mt-2">
                  SOCIO Y/O ACCIONISTA N° {{ index+1 }}
                </h5>
              </b-col>
              <b-col
                sm="12"
              >
                <b-row class="justify-content-center align-items-center my-2">
                  <b-form-group class="m-0">
                    <validation-provider
                      #default="{ errors }"
                      name="tipo de socio"
                      :vid="`${index}partnerPersonType`"
                    >
                      <b-form-radio-group
                        :id="`${index}partnerPersonType`"
                        v-model="partner.partnerPersonType"
                        :options="optionsPerson"
                        value-field="value"
                        text-field="text"
                        :state="errors.length > 0 ? false:null"
                        :name="`${index}partnerPersonType`"
                        :disabled="!canEdit"
                        @change="addPartner(index)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-row>
                <b-row
                  class="justify-content-end"
                >
                  <b-col
                    v-if="partners.length>1 && partner.partnerPersonType == ''"
                    md="4"
                    style="justify-content: flex-end; display: flex;"
                  >
                    <b-button
                      variant="outline-danger"
                      class="my-2 mx-3"
                      :disabled="!canEdit"
                      @click="removePartner(index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-25"
                      />
                      <span class="align-middle">Eliminar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <div
                v-if="partner.partnerPersonType == 'naturalPerson'"
              >
                <b-row
                  class="px-1"
                >
                  <!-- partnerPlastname -->
                  <b-col md="3">
                    <b-form-group
                      label="Apellido parterno*"
                      :label-for="`${index}partnerPlastname`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nombre completo"
                        rules="max:100"
                        :vid="`${index}partnerPlastname`"
                      >
                        <b-form-input
                          :id="`${index}partnerPlastname`"
                          v-model="partner.partnerPlastname"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Gonzales"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerMlastname -->
                  <b-col md="3">
                    <b-form-group
                      label="Apellido materno*"
                      :label-for="`${index}partnerMlastname`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nombre completo"
                        rules="max:100"
                        :vid="`${index}partnerMlastname`"
                      >
                        <b-form-input
                          :id="`${index}partnerMlastname`"
                          v-model="partner.partnerMlastname"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Perez"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerNames -->
                  <b-col md="6">
                    <b-form-group
                      label="Nombres*"
                      :label-for="`${index}partnerNames`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nombres"
                        rules="max:100"
                        :vid="`${index}partnerNames`"
                      >
                        <b-form-input
                          :id="`${index}partnerNames`"
                          v-model="partner.partnerNames"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Juan Perez"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerTypeDocument -->
                  <b-col
                    lg="2"
                    md="4"
                  >
                    <b-form-group
                      label="Tipo de documento*"
                      :label-for="`${index}partnerTypeDocument`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="tipo de documento"
                        :vid="`${index}partnerTypeDocument`"
                      >
                        <b-form-select
                          :id="`${index}partnerTypeDocument`"
                          v-model="partner.partnerTypeDocument"
                          type="text"
                          :options="optionsTypeDocument"
                          :state="errors.length > 0 ? false:null"
                          :name="`${index}partnerTypeDocument`"
                          :disabled="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerNumberDocument -->
                  <b-col
                    lg="3"
                    md="4"
                  >
                    <b-form-group
                      label="Número de documento*"
                      :label-for="`${index}partnerNumberDocument`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="número de documento"
                        :rules="`document:${partners[index].partnerTypeDocument}`"
                        :vid="`${index}partnerNumberDocument`"
                      >
                        <b-form-input
                          :id="`${index}partnerNumberDocument`"
                          v-model="partner.partnerNumberDocument"
                          :disabled="partner.partnerTypeDocument ? false:true"
                          :state="errors.length > 0 ? false:null"
                          :name="`${index}partnerNumberDocument`"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerBorndate -->
                  <b-col
                    lg="3"
                    md="4"
                  >
                    <b-form-group
                      label="Fecha de nacimiento"
                      :label-for="`${index}partnerBorndate`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Fecha de Nacimiento"
                        :vid="`${index}partnerBorndate`"
                      >
                        <!-- <b-form-datepicker
                          :id="`${index}partnerBorndate`"
                          v-model="partner.partnerBorndate"
                          placeholder="dd/mm/aaaa"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="es"
                          :max="maxDate"
                          :name="`${index}partnerBorndate`"
                          :state="errors.length > 0 ? false:null"
                        />
                        <b-form-input
                          v-show="false"
                          v-model="partner.partnerBorndate"
                          :state="errors.length > 0 ? false:null"
                        /> -->
                        <flat-pickr
                          :id="`${index}partnerBorndate`"
                          v-model="partner.partnerBorndate"
                          placeholder="dd/mm/aaaa"
                          :name="`${index}partnerBorndate`"
                          :state="errors.length > 0 ? false:null"
                          :config="{ ...configDatePickr, maxDate, minDate }"
                          :disabled="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerNacionality -->
                  <b-col
                    lg="2"
                    md="6"
                  >
                    <b-form-group
                      label="Nacionalidad*"
                      :label-for="`${index}partnerNacionality`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nacionalidad"
                        :vid="`${index}partnerNacionality`"
                      >
                        <b-form-select
                          :id="`${index}partnerNacionality`"
                          v-model="partner.partnerNacionality"
                          :options="optionsCountry"
                          text-field="varDescripcion"
                          value-field="varCodigo"
                          :state="errors.length > 0 ? false:null"
                          placeholder="peruano"
                          :disabled="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerTelephone -->
                  <b-col
                    lg="4"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="teléfono"
                      rules="phone:1"
                      :vid="`${index}partnerTelephone`"
                    >
                      <b-form-group
                        label="Teléfono fijo"
                        :label-for="`${index}partnerTelephone`"
                      >
                        <b-form-input
                          :id="`${index}partnerTelephone`"
                          v-model="partner.partnerTelephone"
                          :state="errors.length > 0 ? false:null"
                          :formatter="formatterTel"
                          :name="`${index}partnerTelephone`"
                          :readonly="!canEdit"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- partnerCellphone -->
                  <b-col
                    lg="4"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="celular"
                      rules="phone:0"
                      :vid="`${index}partnerCellphone`"
                    >
                      <b-form-group
                        label="Celular"
                        :label-for="`${index}partnerCellphone`"
                      >
                        <b-form-input
                          :id="`${index}partnerCellphone`"
                          v-model="partner.partnerCellphone"
                          :state="errors.length > 0 ? false:null"
                          :formatter="formatterTel"
                          :name="`${index}partnerCellphone`"
                          :readonly="!canEdit"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- partnerEmail -->
                  <b-col
                    lg="4"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="correo electrónico"
                      rules="email"
                      :vid="partners[index].partnerEmail"
                    >
                      <b-form-group
                        label="Correo electrónico"
                        :label-for="`${index}partnerEmail`"
                      >
                        <b-form-input
                          :id="`${index}partnerEmail`"
                          v-model="partner.partnerEmail"
                          :state="errors.length > 0 ? false:null"
                          :name="`${index}partnerEmail`"
                          :readonly="!canEdit"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- partnerPosition -->
                  <b-col
                    lg="4"
                    md="6"
                  >
                    <b-form-group
                      label="Cargo"
                      :label-for="`${index}partnerPosition`"
                    >
                      <v-select
                        :id="`${index}partnerPosition`"
                        v-model="partner.partnerPosition"
                        label="cargo"
                        :options="optionsPositions"
                        :name="`${index}partnerPosition`"
                        :disabled="!canEdit"
                      >
                        <span slot="no-options">
                          Lo sentimos, no hay opciones que coincidan.
                        </span>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <!-- partnerStocks -->
                  <b-col
                    v-if="partner.partnerPosition && partner.partnerPosition.compuestoID==='00010'"
                    lg="2"
                    md="6"
                  >
                    <b-form-group
                      label="Porcentaje de acciones*"
                      :label-for="`${index}partnerStocks`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="porcentaje de acciones"
                        rules="porcentage"
                        :vid="`${index}partnerStocks`"
                      >
                        <b-form-input
                          :id="`${index}partnerStocks`"
                          v-model="partner.partnerStocks"
                          maxlength="3"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="30"
                          :formatter="formatterNumber"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerProfession -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      label="Profesión/Oficio"
                      :label-for="`${index}partnerProfession`"
                    >
                      <v-select
                        :id="`${index}partnerProfession`"
                        v-model="partner.partnerProfession"
                        label="profesion"
                        :options="optionsProfession"
                        :name="`${index}partnerProfession`"
                        :disabled="!canEdit"
                      >
                        <span slot="no-options">
                          Lo sentimos, no hay opciones que coincidan.
                        </span>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <!-- partnerActivity -->
                  <b-col
                    lg="4"
                    md="6"
                  >
                    <b-form-group
                      label="Ocupación"
                      :label-for="`${index}partnerActivity`"
                    >
                      <v-select
                        :id="`${index}partnerActivity`"
                        v-model="partner.partnerActivity"
                        label="ocupacion"
                        :options="optionsActivities"
                        :name="`${index}partnerActivity`"
                        :disabled="!canEdit"
                      >
                        <span slot="no-options">
                          Lo sentimos, no hay opciones que coincidan.
                        </span>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <!-- partnerMainActivity -->
                  <b-col
                    lg="4"
                    md="6"
                  >
                    <b-form-group
                      label="Actividad ecónomica"
                      :label-for="`${index}partnerMainActivity`"
                    >
                      <b-form-input
                        :id="`${index}partnerMainActivity`"
                        v-model="partner.partnerMainActivity"
                        :name="`${index}partnerMainActivity`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerAddress -->
                  <b-col
                    lg="4"
                    md="6"
                  >
                    <b-form-group
                      label="Domicilio*"
                      :label-for="`${index}partnerAddress`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="domicilio"
                        rules="max:150"
                        :vid="`${index}partnerAddress`"
                      >
                        <b-form-input
                          :id="`${index}partnerAddress`"
                          v-model="partner.partnerAddress"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Los Melones 350"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerStreet -->
                  <b-col
                    lg="4"
                    md="4"
                  >
                    <b-form-group
                      label="Dirección*"
                      :label-for="`${index}partnerStreet`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Dirección"
                        :vid="`${index}partnerStreet`"
                      >
                        <b-form-input
                          :id="`${index}partnerStreet`"
                          v-model="partner.partnerStreet"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Los Melones 350"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerStreetNumber -->
                  <b-col
                    lg="2"
                    md="4"
                  >
                    <b-form-group
                      label="Número"
                      :label-for="`${index}partnerStreetNumber`"
                    >
                      <b-form-input
                        :id="`${index}partnerStreetNumber`"
                        v-model="partner.partnerStreetNumber"
                        :name="`${index}partnerStreetNumber`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerBlock -->
                  <b-col
                    lg="2"
                    md="4"
                  >
                    <b-form-group
                      label="Manzana"
                      :label-for="`${index}partnerBlock`"
                    >
                      <b-form-input
                        :id="`${index}partnerBlock`"
                        v-model="partner.partnerBlock"
                        :name="`${index}partnerBlock`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerLot -->
                  <b-col
                    lg="2"
                    md="4"
                  >
                    <b-form-group
                      label="Lote"
                      :label-for="`${index}partnerLot`"
                    >
                      <b-form-input
                        :id="`${index}partnerLot`"
                        v-model="partner.partnerLot"
                        :name="`${index}partnerLot`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerApartmentNumber -->
                  <b-col
                    lg="2"
                    md="4"
                  >
                    <b-form-group
                      label="Departamento"
                      :label-for="`${index}partnerApartmentNumber`"
                    >
                      <b-form-input
                        :id="`${index}partnerApartmentNumber`"
                        v-model="partner.partnerApartmentNumber"
                        :name="`${index}partnerApartmentNumber`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerInside -->
                  <b-col
                    lg="2"
                    md="4"
                  >
                    <b-form-group
                      label="Interior"
                      :label-for="`${index}partnerInside`"
                    >
                      <b-form-input
                        :id="`${index}partnerInside`"
                        v-model="partner.partnerInside"
                        :name="`${index}partnerInside`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- UBIGEO -->
                  <b-col
                    lg="3"
                    md="4"
                  >
                    <!-- ubigee -->
                    <b-form-group
                      label="Ubigeo*"
                      :label-for="`${index}partnerUbigee`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="ubigeo"
                        rules="min:6|max:6"
                        :vid="`${index}partnerUbigee`"
                      >
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              v-b-tooltip.hover.top="'Ubigeo son las siglas oficiales para Código de Ubicación Geográfica que usan el INEI, el RENIEC y la ONPE para codificar las circunscripciones'"
                              variant="outline-primary"
                              class="btn-icon"
                            >
                              <feather-icon
                                size="15"
                                icon="InfoIcon"
                              />
                            </b-button>
                          </b-input-group-prepend>
                          <b-form-input
                            :id="`${index}partnerUbigee`"
                            v-model="partner.partnerUbigee"
                            :formatter="formatterNumber"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}partnerUbigee`"
                            :readonly="!canEdit"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-row
                      class="justify-content-center m-2"
                    >
                      <b-badge
                        href="/ubigeo"
                        variant="light-primary"
                        target="_blank"
                        style="white-space: pre-wrap;"
                      > Puede encontrar su UBIGEO haciendo click AQUÍ </b-badge>
                    </b-row>
                  </b-col>
                  <!-- partnerCountry -->
                  <b-col
                    lg="3"
                    md="6"
                  >
                    <b-form-group
                      label="País de residencia"
                      :label-for="`${index}partnerCountry`"
                    >
                      <b-form-select
                        :id="`${index}partnerCountry`"
                        v-model="partner.partnerCountry"
                        :options="optionsCountry"
                        text-field="varDescripcion"
                        value-field="varCodigo"
                        :name="`${index}partnerCountry`"
                        :disabled="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- isResident -->
                  <b-col
                    xl="3"
                    lg="3"
                    md="6"
                    class="my-2 mx-auto"
                  >
                    <b-row class="justify-content-center">
                      <b-form-group
                        label="¿Reside actualmente?*"
                        :label-for="`${index}partnerIsResident`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="residencia"
                          rules="required"
                          :vid="`${index}partnerIsResident`"
                        >
                          <b-form-radio-group
                            :id="`${index}partnerIsResident`"
                            v-model="partner.partnerIsResident"
                            class="text-center mt-1"
                            :options="optionsPublic"
                            value-field="value"
                            text-field="text"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}partnerIsResident`"
                            :disabled="!canEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row
                  class="px-1"
                >
                  <!-- partnerIsPEP -->
                  <b-col
                    xl="3"
                    lg="4"
                    md="5"
                    class="my-2 mx-auto"
                  >
                    <b-row class="justify-content-center">
                      <b-form-group
                        label="¿Ha ocupado cargo público?"
                        :label-for="`${index}partnerIsPEP`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="cargo público"
                          :vid="`${index}partnerIsPEP`"
                        >
                          <b-form-radio-group
                            :id="`${index}partnerIsPEP`"
                            v-model="partner.partnerIsPEP"
                            class="text-center mt-1"
                            :options="optionsPublic"
                            value-field="value"
                            text-field="text"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}partnerIsPEP`"
                            :disabled="!canEdit"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-row>
                  </b-col>
                  <!-- Is PEP -->
                  <b-col
                    v-if="partner.partnerIsPEP == 'yes'"
                    cols="12"
                  >
                    <b-row
                      class="repeater-form justify-content-center"
                    >
                      <!-- PEP section -->
                      <b-col
                        md="10"
                        style="text-align: center"
                      >
                        <h4 class="mt-4 mb-2">
                          DATOS DE LA PERSONA JURÍDICA RELACIONADA (OBLIGATORIO)
                        </h4>
                      </b-col>
                      <!-- PEP Data -->
                      <b-col md="8">
                        <b-row
                          class="justify-content-md-center mt-3 mb-3"
                        >
                          <!-- Bussiness name section -->
                          <b-col md="12">
                            <b-form-group
                              label="Nombre de la institución, organismo público u organización internacional"
                              :label-for="`${index}partnerPepBusinessName`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Nombre de la institución, organismo público u organización internacional"
                                rules="max:100"
                                :vid="`${index}partnerPepBusinessName`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepBusinessName`"
                                  v-model="partner.partnerPepBusinessName"
                                  :state="errors.length > 0 ? false:null"
                                  name="partnerPepBusinessName"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- document number -->
                          <b-col md="12">
                            <b-form-group
                              label="R.U.C."
                              :label-for="`${index}partnerPepRuc`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="R.U.C."
                                rules="min:11"
                                :vid="`${index}partnerPepRuc`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepRuc`"
                                  v-model="partner.partnerPepRuc"
                                  maxlength="11"
                                  :state="errors.length > 0 ? false:null"
                                  name="partnerPepRuc"
                                  :formatter="formatterNumber"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- text -->
                          <b-col
                            style="text-align: center"
                            md="12"
                          >
                            <p class="my-3">
                              Si Ud. desempeña o ha desempeñado en los últimos 5 años algún cargo político,
                              en la administración pública o en organización internacional, señale
                            </p>
                          </b-col>
                          <!-- position -->
                          <b-col md="12">
                            <b-form-group
                              label="Cargo que desempeña o ha desempeñado"
                              :label-for="`${index}partnerPepPosition`"
                            >
                              <b-form-select
                                :id="`${index}partnerPepPosition`"
                                v-model="partner.partnerPepPosition"
                                :options="optionsPublicOffices"
                                value-field="varCodigo"
                                text-field="cargoPublico"
                                :name="`${index}partnerPepPosition`"
                                :disabled="!canEdit"
                              />
                            </b-form-group>
                          </b-col>
                          <!-- start date position -->
                          <b-col md="12">
                            <b-form-group
                              label="Desde"
                              :label-for="`${index}partnerPepStartDate`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Fecha de inicio"
                                :vid="`${index}partnerPepStartDate`"
                              >
                                <!-- <b-form-datepicker
                                  :id="`${index}partnerPepStartDate`"
                                  v-model="partner.partnerPepStartDate"
                                  placeholder=""
                                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                  locale="es"
                                  :max="(partner.partnerPepEndDate!=='' ? partner.partnerPepEndDate : today)"
                                  name="partnerPepStartDate"
                                  :state="errors.length > 0 ? false:null"
                                />
                                <b-form-input
                                  v-show="false"
                                  v-model="partner.partnerPepStartDate"
                                  :state="errors.length > 0 ? false:null"
                                /> -->
                                <flat-pickr
                                  :id="`${index}partnerPepStartDate`"
                                  v-model="partner.partnerPepStartDate"
                                  placeholder="dd/mm/aaaa"
                                  :name="`${index}partnerPepStartDate`"
                                  :state="errors.length > 0 ? false:null"
                                  :config="{ ...configDatePickr, maxDate: (partner.partnerPepEndDate!=='' ? partner.partnerPepEndDate : today) }"
                                  :disabled="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-row class="justify-content-center mt-3 mb-2">
                            <b-form-group
                              label="¿Sigue desempeñando el cargo?"
                              :label-for="`${index}partnerStillPosition`"
                            >
                              <b-form-radio-group
                                :id="`${index}partnerStillPosition`"
                                v-model="partner.partnerStillPosition"
                                class="text-center mt-1"
                                :options="optionsPublic"
                                value-field="value"
                                text-field="text"
                                :name="`${index}partnerStillPosition`"
                                :disabled="!canEdit"
                              />
                            </b-form-group>
                          </b-row>
                          <!-- end date position -->
                          <b-col md="12">
                            <b-form-group
                              v-if="partner.partnerStillPosition === 'no'"
                              label="Hasta"
                              :label-for="`${index}partnerPepEndDate`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Fecha de fin"
                                :vid="`${index}partnerPepEndDate`"
                              >
                                <!-- <b-form-datepicker
                                  :id="`${index}partnerPepEndDate`"
                                  v-model="partner.partnerPepEndDate"
                                  placeholder=""
                                  :min="partner.partnerPepStartDate"
                                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                  locale="es"
                                  :max="today"
                                  name="partnerPepEndDate"
                                  :state="errors.length > 0 ? false:null"
                                />
                                <b-form-input
                                  v-show="false"
                                  v-model="partner.partnerPepEndDate"
                                  :state="errors.length > 0 ? false:null"
                                /> -->
                                <flat-pickr
                                  :id="`${index}partnerPepEndDate`"
                                  v-model="partner.partnerPepEndDate"
                                  placeholder="dd/mm/aaaa"
                                  :name="`${index}partnerPepEndDate`"
                                  :state="errors.length > 0 ? false:null"
                                  :config="{ ...configDatePickr, minDate: partner.partnerPepStartDate, maxDate: today }"
                                  :disabled="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- institution -->
                          <!-- <b-col md="12">
                            <b-form-group
                              label="Nombre de la institución, organismo público u organización internacional"
                              :label-for="`${index}partnerPepInstitution`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Nombre de la institución, organismo público u organización internacional"
                                rules="max:50"
                                :vid="`${index}partnerPepInstitution`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepInstitution`"
                                  v-model="partner.partnerPepInstitution"
                                  :state="errors.length > 0 ? false:null"
                                  name="partnerPepInstitution"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col> -->
                        </b-row>
                      </b-col>
                      <!-- Family PEP Section-->
                      <b-col
                        md="10"
                        style="text-align: center"
                      >
                        <h4 class="my-2">
                          DATOS DE LOS FAMILIARES DEL PEP
                        </h4>
                      </b-col>
                      <b-col md="8">
                        <!-- text -->
                        <b-col
                          style="text-align: left"
                          md="12"
                        >
                          <p class="my-1">
                            De ser PEP, indique los datos de sus parientes vivos hasta el 2do. grado de consanguinidad y 2do. grado de afinidad.
                          </p>
                          <p class="my-1">
                            Utilice los siguientes códigos para identificarlos:
                            A =padres, B=abuelos, C=hermanos, D=hijos en común y propios, E=nietos
                          </p>
                          <p class="my-1">
                            Información solicitada de acuerdo a la Resolución SBS Nº 5060-2018
                          </p>
                        </b-col>
                      </b-col>
                      <!-- Family PEP Data-->
                      <b-col
                        v-for="( familiarPEP, i ) in partner.partnerPepFamilyPEP"
                        :key="familiarPEP+i"
                        md="10"
                        class="container-border py-1 my-1"
                      >
                        <b-row
                          class="justify-content-md-center my-1"
                        >
                          <!-- header familiar PEP -->
                          <b-col
                            md="10"
                            style="text-align: center"
                          >
                            <h4 class="mb-3">
                              DATOS DEL FAMILIAR N° {{ i + 1 }}
                            </h4>
                          </b-col>
                          <!-- family bond familiar PEP -->
                          <b-col md="4">
                            <b-form-group
                              label="Vínculo"
                              :label-for="`${index}partnerPepFamilyPEP-${i}familiarPEPbond`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Vínculo"
                                :vid="`${index}partnerPepFamilyPEP-${i}familiarPEPbond`"
                              >
                                <b-form-select
                                  :id="`${index}partnerPepFamilyPEP-${i}familiarPEPbond`"
                                  v-model="familiarPEP.familiarPEPbond"
                                  :options="codeFamiliarPEP"
                                  :state="errors.length > 0 ? false:null"
                                  name="familiarPEPbond"
                                  :disabled="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- plastname familiar PEP -->
                          <b-col md="4">
                            <b-form-group
                              label="Apellido paterno"
                              :label-for="`${index}partnerPepFamilyPEP-${i}familiarPEPplastname`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Apellido paterno"
                                rules="max:20"
                                :vid="`${index}partnerPepFamilyPEP-${i}familiarPEPplastname`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepFamilyPEP-${i}familiarPEPplastname`"
                                  v-model="familiarPEP.familiarPEPplastname"
                                  :state="errors.length > 0 ? false:null"
                                  name="familiarPEPplastname"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- mlastname familiar PEP -->
                          <b-col md="4">
                            <b-form-group
                              label="Apellido materno"
                              :label-for="`${index}partnerPepFamilyPEP-${i}familiarPEPmlastname`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Apellido materno"
                                rules="max:20"
                                :vid="`${index}partnerPepFamilyPEP-${i}familiarPEPmlastname`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepFamilyPEP-${i}familiarPEPmlastname`"
                                  v-model="familiarPEP.familiarPEPmlastname"
                                  :state="errors.length > 0 ? false:null"
                                  name="familiarPEPmlastname"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- names familiar PEP -->
                          <b-col md="4">
                            <b-form-group
                              label="Nombres"
                              :label-for="`${index}partnerPepFamilyPEP-${i}familiarPEPnames`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Nombres"
                                rules="max:100"
                                :vid="`${index}partnerPepFamilyPEP-${i}familiarPEPnames`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepFamilyPEP-${i}familiarPEPnames`"
                                  v-model="familiarPEP.familiarPEPnames"
                                  :state="errors.length > 0 ? false:null"
                                  name="familiarPEPnames"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- age familiar PEP -->
                          <b-col md="4">
                            <b-form-group
                              label="Edad estimada"
                              :label-for="`${index}partnerPepFamilyPEP-${i}familiarPEPage`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Edad estimada"
                                rules="max:2"
                                :vid="`${index}partnerPepFamilyPEP-${i}familiarPEPage`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepFamilyPEP-${i}familiarPEPage`"
                                  v-model="familiarPEP.familiarPEPage"
                                  :state="errors.length > 0 ? false:null"
                                  name="familiarPEPage"
                                  maxlength="2"
                                  :formatter="formatterNumber"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- isPEP familiar PEP -->
                          <b-col
                            xl="3"
                            lg="4"
                            md="5"
                          >
                            <b-row class="justify-content-center">
                              <b-form-group
                                label="¿El familiar es PEP?"
                                :label-for="`${index}partnerPepFamilyPEP-${i}familiarPEPisPEP`"
                              >
                                <b-form-radio-group
                                  :id="`${index}partnerPepFamilyPEP-${i}familiarPEPisPEP`"
                                  v-model="familiarPEP.familiarPEPisPEP"
                                  class="text-center mt-1"
                                  :options="optionsPublic"
                                  value-field="value"
                                  text-field="text"
                                  :name="`${index}partnerPepFamilyPEP-${i}familiarPEPisPEP`"
                                  :disabled="!canEdit"
                                />
                              </b-form-group>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="familiarPEP.familiarPEPisPEP == 'yes'"
                          ref="box1"
                          class="repeater-form justify-content-center"
                        >
                          <!-- country familiar PEP -->
                          <b-col md="12">
                            <b-form-group
                              label="País donde desarrolla o ha desarrollado la  función pública"
                              :label-for="`${index}partnerPepFamilyPEP-${i}familiarPEPcountry`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="País donde desarrolla o ha desarrollado la  función pública"
                                :vid="`${index}partnerPepFamilyPEP-${i}familiarPEPcountry`"
                              >
                                <b-form-select
                                  :id="`${index}partnerPepFamilyPEP-${i}familiarPEPcountry`"
                                  v-model="familiarPEP.familiarPEPcountry"
                                  :options="optionsCountry"
                                  text-field="varDescripcion"
                                  value-field="varCodigo"
                                  :state="errors.length > 0 ? false:null"
                                  name="familiarPEPcountry"
                                  :disabled="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- institution familiar PEP -->
                          <b-col md="12">
                            <b-form-group
                              label="Nombre de la institución, organismo público u organización internacional"
                              :label-for="`${index}partnerPepFamilyPEP-${i}familiarPEPinstitution`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Nombre de la institución, organismo público u organización internacional"
                                :vid="`${index}partnerPepFamilyPEP-${i}familiarPEPinstitution`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepFamilyPEP-${i}familiarPEPinstitution`"
                                  v-model="familiarPEP.familiarPEPinstitution"
                                  :state="errors.length > 0 ? false:null"
                                  name="familiarPEPinstitution"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- occupation familiar PEP -->
                          <b-col md="12">
                            <b-form-group
                              label="Cargo que tiene o ha desempeñado"
                              :label-for="`${index}partnerPepFamilyPEP-${i}familiarPEPoccupation`"
                            >
                              <b-form-select
                                :id="`${index}partnerPepFamilyPEP-${i}familiarPEPoccupation`"
                                v-model="familiarPEP.familiarPEPoccupation"
                                :options="optionsPublicOffices"
                                value-field="varCodigo"
                                text-field="cargoPublico"
                                :name="`${index}partnerPepFamilyPEP-${i}familiarPEPoccupation`"
                                :disabled="!canEdit"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <!-- Buttons -->
                        <b-row style="justify-content: flex-end;">
                          <b-col md="6">
                            <b-row>
                              <!-- Add Button -->
                              <b-col class="mb-50">
                                <b-row class="justify-content-center">
                                  <b-button
                                    variant="primary"
                                    class="m-1 mt-md-2"
                                    :disabled="!canEdit"
                                    @click="addFamiliarPEP(index)"
                                  >
                                    <feather-icon
                                      icon="PlusIcon"
                                      class="mr-25"
                                    />
                                    <span class="align-middle">Añadir nuevo</span>
                                  </b-button>
                                </b-row>
                              </b-col>
                              <!-- Remove Button -->
                              <b-col class="mb-50">
                                <b-row class="justify-content-center">
                                  <b-button
                                    v-if="partner.partnerPepFamilyPEP.length>0"
                                    variant="outline-danger"
                                    class="m-1 mt-md-2"
                                    :disabled="!canEdit"
                                    @click="removeFamiliarPEP(index, i)"
                                  >
                                    <feather-icon
                                      icon="Trash2Icon"
                                      class="mr-25"
                                    />
                                    <span class="align-middle">Eliminar</span>
                                  </b-button>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <!-- add familiar PEP-->
                      <b-col
                        v-if="partner.partnerPepFamilyPEP.length==0"
                        md="8"
                      >
                        <b-col md="4">
                          <!-- submit button -->
                          <b-button
                            class="my-1"
                            variant="secondary"
                            block
                            type="button"
                            :disabled="!canEdit"
                            @click="addFamiliarPEP(index)"
                          >
                            Agregar familiar
                          </b-button>
                        </b-col>
                      </b-col>
                      <!-- Spouse Family PEP Section-->
                      <b-col
                        md="10"
                        style="text-align: center"
                      >
                        <h4 class="my-2">
                          DATOS DEL/DE LA CÓNYUGE O CONVIVIENTE DEL PEP Y SUS FAMILIARES (DEL/DE LA CÓNYUGE)
                        </h4>
                      </b-col>
                      <!-- has Spouse PEP -->
                      <b-col
                        md="10"
                        class="my-2"
                      >
                        <b-row class="justify-content-center">
                          <b-form-group
                            label="¿Es usted casado(a) o tiene conviviente?"
                            :label-for="`${index}partnerPepHasSpouse`"
                          >
                            <b-form-radio-group
                              :id="`${index}partnerPepHasSpouse`"
                              v-model="partner.partnerPepHasSpouse"
                              class="text-center mt-1"
                              :options="optionsPublic"
                              value-field="value"
                              text-field="text"
                              :name="`${index}partnerPepHasSpouse`"
                              :disabled="!canEdit"
                            />
                          </b-form-group>
                        </b-row>
                      </b-col>
                      <!-- Spouse PEP section -->
                      <b-row
                        v-if="partner.partnerPepHasSpouse == 'yes'"
                        ref="box1"
                        class="repeater-form justify-content-center"
                      >
                        <!-- Spouse Family PEP Data-->
                        <b-col
                          v-for="( familiarSpousePEP, i ) in partner.partnerPepFamilySpousePEP"
                          :key="familiarSpousePEP+i+2"
                          md="10"
                          class="container-border py-1 my-1"
                        >
                          <b-row
                            class="justify-content-md-center my-1"
                          >
                            <!-- header familiar spouse PEP -->
                            <b-col
                              md="10"
                              style="text-align: center"
                            >
                              <h4
                                v-if="i >= 1"
                                class="mb-3"
                              >
                                DATOS DEL FAMILIAR N° {{ i }} (DEL/DE LA) CONYUGE
                              </h4>
                              <h4
                                v-else
                                class="mb-3"
                              >
                                DATOS DEL/DE LA CÓNYUGE
                              </h4>
                            </b-col>
                            <!-- plastname familiar spouse PEP -->
                            <b-col md="4">
                              <b-form-group
                                label="Apellido paterno"
                                :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPplastname`"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Apellido paterno"
                                  rules="max:20"
                                  :vid="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPplastname`"
                                >
                                  <b-form-input
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPplastname`"
                                    v-model="familiarSpousePEP.familiarSpousePEPplastname"
                                    :state="errors.length > 0 ? false:null"
                                    name="familiarSpousePEPplastname"
                                    :readonly="!canEdit"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- mlastname familiar spouse PEP -->
                            <b-col md="4">
                              <b-form-group
                                label="Apellido materno"
                                :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPmlastname`"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Apellido materno"
                                  rules="max:20"
                                  :vid="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPmlastname`"
                                >
                                  <b-form-input
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPmlastname`"
                                    v-model="familiarSpousePEP.familiarSpousePEPmlastname"
                                    :state="errors.length > 0 ? false:null"
                                    name="familiarSpousePEPmlastname"
                                    :readonly="!canEdit"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- names familiar spouse PEP -->
                            <b-col md="4">
                              <b-form-group
                                label="Nombres"
                                :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPnames`"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Nombres"
                                  rules="max:100"
                                  :vid="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPnames`"
                                >
                                  <b-form-input
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPnames`"
                                    v-model="familiarSpousePEP.familiarSpousePEPnames"
                                    :state="errors.length > 0 ? false:null"
                                    name="familiarSpousePEPnames"
                                    :readonly="!canEdit"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- Born Date familiar spouse PEP -->
                            <b-col md="4">
                              <b-form-group
                                label="Fecha de nacimiento"
                                :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPbornDate`"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Fecha de Nacimiento"
                                  :vid="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPbornDate`"
                                >
                                  <!-- <b-form-datepicker
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPbornDate`"
                                    v-model="familiarSpousePEP.familiarSpousePEPbornDate"
                                    locale="es"
                                    placeholder=""
                                    :max="isAdult"
                                    name="familiarSpousePEPbornDate"
                                    :state="errors.length > 0 ? false:null"
                                    class="PEP-Container-datepicker"
                                  />
                                  <b-form-input
                                    v-show="false"
                                    v-model="familiarSpousePEP.familiarSpousePEPbornDate"
                                    :state="errors.length > 0 ? false:null"
                                  /> -->
                                  <flat-pickr
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPbornDate`"
                                    v-model="familiarSpousePEP.familiarSpousePEPbornDate"
                                    placeholder="dd/mm/aaaa"
                                    :name="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPbornDate`"
                                    :state="errors.length > 0 ? false:null"
                                    :config="{ ...configDatePickr, maxDate: isAdult, minDate }"
                                    :disabled="!canEdit"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- Type Document familiar spouse PEP -->
                            <b-col md="4">
                              <b-form-group
                                label="Tipo de documento"
                                :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPtypeDocument`"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Tipo de documento"
                                  :vid="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPtypeDocument`"
                                >
                                  <b-form-select
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPtypeDocument`"
                                    v-model="familiarSpousePEP.familiarSpousePEPtypeDocument"
                                    :options="optionsTypeDocument"
                                    :state="errors.length > 0 ? false:null"
                                    name="familiarSpousePEPtypeDocument"
                                    :disabled="!canEdit"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- Number Document familiar spouse PEP -->
                            <b-col md="4">
                              <b-form-group
                                label="Número de documento"
                                :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPnumberDocument`"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Número de Documento"
                                  :rules="`document:${familiarSpousePEP.familiarSpousePEPtypeDocument}`"
                                  :vid="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPnumberDocument`"
                                >
                                  <b-form-input
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPnumberDocument`"
                                    v-model="familiarSpousePEP.familiarSpousePEPnumberDocument"
                                    :disabled="familiarSpousePEP.familiarSpousePEPtypeDocument ? false:true"
                                    :state="errors.length > 0 ? false:null"
                                    name="familiarSpousePEPnumberDocument"
                                    :readonly="!canEdit"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- isPEP familiar spouse PEP -->
                            <b-col
                              xl="3"
                              lg="4"
                              md="5"
                            >
                              <b-row class="justify-content-center">
                                <b-form-group
                                  label="¿El familiar es PEP?"
                                  :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPisPEP`"
                                >
                                  <b-form-radio-group
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPisPEP`"
                                    v-model="familiarSpousePEP.familiarSpousePEPisPEP"
                                    class="text-center mt-1"
                                    :options="optionsPublic"
                                    value-field="value"
                                    text-field="text"
                                    :name="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPisPEP`"
                                    :disabled="!canEdit"
                                  />
                                </b-form-group>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="familiarSpousePEP.familiarSpousePEPisPEP == 'yes'"
                            ref="box1"
                            class="repeater-form justify-content-center"
                          >
                            <!-- country familiar spouse PEP -->
                            <b-col md="12">
                              <b-form-group
                                label="País donde desarrolla o ha desarrollado la  función pública"
                                :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPcountry`"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="País donde desarrolla o ha desarrollado la  función pública"
                                  :vid="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPcountry`"
                                >
                                  <b-form-select
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPcountry`"
                                    v-model="familiarSpousePEP.familiarSpousePEPcountry"
                                    :options="optionsCountry"
                                    text-field="varDescripcion"
                                    value-field="varCodigo"
                                    :state="errors.length > 0 ? false:null"
                                    name="familiarSpousePEPcountry"
                                    :disabled="!canEdit"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- institution familiar spouse PEP -->
                            <b-col md="12">
                              <b-form-group
                                label="Nombre de la institución, organismo público u organización internacional"
                                :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPinstitution`"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Nombre de la institución, organismo público u organización internacional"
                                  :vid="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPinstitution`"
                                >
                                  <b-form-input
                                    :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPinstitution`"
                                    v-model="familiarSpousePEP.familiarSpousePEPinstitution"
                                    :state="errors.length > 0 ? false:null"
                                    name="familiarSpousePEPinstitution"
                                    :readonly="!canEdit"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- occupation familiar spouse PEP -->
                            <b-col md="12">
                              <b-form-group
                                label="Cargo que tiene o ha desempeñado"
                                :label-for="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPoccupation`"
                              >
                                <b-form-select
                                  :id="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPoccupation`"
                                  v-model="familiarSpousePEP.familiarSpousePEPoccupation"
                                  :options="optionsPublicOffices"
                                  value-field="varCodigo"
                                  text-field="cargoPublico"
                                  :name="`${index}partnerPepFamilySpousePEP-${i}familiarSpousePEPoccupation`"
                                  :disabled="!canEdit"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <!-- Buttons -->
                          <b-row style="justify-content: flex-end;">
                            <b-col md="6">
                              <b-row>
                                <!-- Add Button -->
                                <b-col class="mb-50">
                                  <b-row class="justify-content-center">
                                    <b-button
                                      variant="primary"
                                      class="m-1 mt-md-2"
                                      :disabled="!canEdit"
                                      @click="addFamiliarSpousePEP(index)"
                                    >
                                      <feather-icon
                                        icon="PlusIcon"
                                        class="mr-25"
                                      />
                                      <span class="align-middle">Añadir nuevo</span>
                                    </b-button>
                                  </b-row>
                                </b-col>
                                <!-- Remove Button -->
                                <b-col class="mb-50">
                                  <b-row class="justify-content-center">
                                    <b-button
                                      v-if="partner.partnerPepFamilySpousePEP.length>1"
                                      variant="outline-danger"
                                      class="m-1 mt-md-2"
                                      :disabled="!canEdit"
                                      @click="removeFamiliarSpousePEP(index, i)"
                                    >
                                      <feather-icon
                                        icon="Trash2Icon"
                                        class="mr-25"
                                      />
                                      <span class="align-middle">Eliminar</span>
                                    </b-button>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                        <!-- add familiar Spouse PEP-->
                        <b-col
                          v-if="partner.partnerPepFamilySpousePEP.length==0"
                          md="8"
                        >
                          <b-col md="4">
                            <!-- submit button -->
                            <b-button
                              class="my-1"
                              variant="secondary"
                              block
                              type="button"
                              :disabled="!canEdit"
                              @click="addFamiliarSpousePEP(index)"
                            >
                              Agregar familiar
                            </b-button>
                          </b-col>
                        </b-col>
                      </b-row>
                      <!-- Header shares Legal Representative -->
                      <b-col
                        md="8"
                        style="text-align: center"
                      >
                        <h4 class="mb-2 mt-3">
                          EMPRESAS EN LAS QUE USTED ES ACCIONISTA CON EL 25% O MAS DE PARTICIPACIÓN
                        </h4>
                      </b-col>
                      <b-col
                        v-for="( share, i ) in partner.partnerPepShares"
                        :key="share+i+3"
                        md="10"
                        class="container-border py-1 my-1"
                      >
                        <b-row
                          class="justify-content-md-center my-1"
                        >
                          <b-col
                            md="10"
                            style="text-align: center"
                          >
                            <h4 class="my-1">
                              DATOS DE LA EMPRESA N° {{ i+1 }}
                            </h4>
                          </b-col>
                          <!-- Business name section -->
                          <b-col md="12">
                            <b-form-group
                              label="Razón social"
                              :label-for="`${index}partnerPepShares-${i}businessName`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Razón social"
                                rules="max:100"
                                :vid="`${index}partnerPepShares-${i}businessName`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepShares-${i}businessName`"
                                  v-model="share.businessName"
                                  :state="errors.length > 0 ? false:null"
                                  name="businessName"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- document number -->
                          <b-col md="12">
                            <b-form-group
                              label="R.U.C."
                              :label-for="`${index}partnerPepShares-${i}businessRuc`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="R.U.C."
                                rules="min:11"
                                :vid="`${index}partnerPepShares-${i}businessRuc`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepShares-${i}businessRuc`"
                                  v-model="share.businessRuc"
                                  maxlength="20"
                                  :state="errors.length > 0 ? false:null"
                                  name="businessRuc"
                                  :formatter="formatterNumber"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group
                              label="Porcentaje de participación"
                              :label-for="`${index}partnerPepShares-${i}businessPercentage`"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Porcentaje de participación"
                                :vid="`${index}partnerPepShares-${i}businessPercentage`"
                              >
                                <b-form-input
                                  :id="`${index}partnerPepShares-${i}businessPercentage`"
                                  v-model="share.businessPercentage"
                                  maxlength="4"
                                  :state="errors.length > 0 ? false:null"
                                  name="businessPercentage"
                                  :formatter="formatterDecimal"
                                  :readonly="!canEdit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <!-- Buttons -->
                        <b-row style="justify-content: flex-end;">
                          <b-col md="6">
                            <b-row>
                              <!-- Add Button -->
                              <b-col class="mb-50">
                                <b-row class="justify-content-center">
                                  <b-button
                                    variant="primary"
                                    class="m-1 mt-md-2"
                                    :disabled="!canEdit"
                                    @click="addShares(index)"
                                  >
                                    <feather-icon
                                      icon="PlusIcon"
                                      class="mr-25"
                                    />
                                    <span class="align-middle">Añadir nuevo</span>
                                  </b-button>
                                </b-row>
                              </b-col>
                              <!-- Remove Button -->
                              <b-col class="mb-50">
                                <b-row class="justify-content-center">
                                  <b-button
                                    v-if="partner.partnerPepShares.length>0"
                                    variant="outline-danger"
                                    class="m-1 mt-md-2"
                                    :disabled="!canEdit"
                                    @click="removeShares(index, i)"
                                  >
                                    <feather-icon
                                      icon="Trash2Icon"
                                      class="mr-25"
                                    />
                                    <span class="align-middle">Eliminar</span>
                                  </b-button>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <!-- add shares PEP-->
                      <b-col
                        v-if="partner.partnerPepShares.length==0"
                        md="8"
                      >
                        <b-col md="4">
                          <!-- submit button -->
                          <b-button
                            class="my-1"
                            variant="secondary"
                            block
                            type="button"
                            :disabled="!canEdit"
                            @click="addShares(index)"
                          >
                            Agregar empresa
                          </b-button>
                        </b-col>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row
                  class="justify-content-end"
                >
                  <!-- buttons -->
                  <b-col md="6">
                    <b-row>
                      <b-col class="mb-50">
                        <b-row class="justify-content-center">
                          <b-button
                            variant="primary"
                            class="m-1 mt-md-2"
                            :disabled="!canEdit"
                            @click="newPartner(index)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-25"
                            />
                            <span class="align-middle">Añadir nuevo</span>
                          </b-button>
                        </b-row>
                      </b-col>
                      <b-col
                        v-if="partners.length>1"
                        class="mb-50"
                      >
                        <b-row class="justify-content-center">
                          <b-button
                            variant="outline-danger"
                            class="m-1 mt-md-2"
                            :disabled="!canEdit"
                            @click="removePartner(index)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              class="mr-25"
                            />
                            <span class="align-middle">Eliminar</span>
                          </b-button>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <div
                v-else-if="partner.partnerPersonType == 'legalPerson'"
              >
                <b-row>
                  <!-- partnerBussinessName -->
                  <b-col
                    lg="4"
                    md="4"
                  >
                    <b-form-group
                      label="Razón social*"
                      :label-for="`${index}partnerBussinessName`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="razón social"
                        rules="max:100"
                        :vid="`${index}partnerBussinessName`"
                      >
                        <b-form-input
                          :id="`${index}partnerBussinessName`"
                          v-model="partner.partnerBussinessName"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Juan Perez"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerRuc -->
                  <b-col
                    lg="4"
                    md="4"
                  >
                    <b-form-group
                      label="RUC*"
                      :label-for="`${index}partnerRuc`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="RUC"
                        rules="ruc"
                        :vid="`${index}partnerRuc`"
                      >
                        <b-form-input
                          :id="`${index}partnerRuc`"
                          v-model="partner.partnerRuc"
                          :state="errors.length > 0 ? false:null"
                          :formatter="formatterRuc"
                          name="ruc"
                          placeholder="johndoe"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerTelephone -->
                  <b-col
                    lg="4"
                    md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="teléfono"
                      rules="phone:1"
                      :vid="`${index}partnerTelephone`"
                    >
                      <b-form-group
                        label="Teléfono fijo*"
                        :label-for="`${index}partnerTelephone`"
                      >
                        <b-form-input
                          :id="`${index}partnerTelephone`"
                          v-model="partner.partnerTelephone"
                          :state="errors.length > 0 ? false:null"
                          :formatter="formatterTel"
                          :name="`${index}partnerTelephone`"
                          :readonly="!canEdit"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- partnerCellphone -->
                  <b-col
                    lg="4"
                    md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="celular"
                      rules="phone:0"
                      :vid="`${index}partnerCellphone`"
                    >
                      <b-form-group
                        label="Celular*"
                        :label-for="`${index}partnerCellphone`"
                      >
                        <b-form-input
                          :id="`${index}partnerCellphone`"
                          v-model="partner.partnerCellphone"
                          :state="errors.length > 0 ? false:null"
                          :formatter="formatterTel"
                          :name="`${index}partnerCellphone`"
                          :readonly="!canEdit"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- partnerEmail -->
                  <b-col
                    lg="4"
                    md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="correo electrónico"
                      rules="email"
                      :vid="`${index}partnerEmail`"
                    >
                      <b-form-group
                        label="Correo electrónico*"
                        :label-for="`${index}partnerEmail`"
                      >
                        <b-form-input
                          :id="`${index}partnerEmail`"
                          v-model="partner.partnerEmail"
                          :state="errors.length > 0 ? false:null"
                          :name="`${index}partnerEmail`"
                          :readonly="!canEdit"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- partnerMainActivity -->
                  <b-col
                    lg="4"
                    md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="actividad ecónomica*"
                      :vid="`${index}partnerMainActivity`"
                    >
                      <b-form-group
                        label="Actividad ecónomica*"
                        :label-for="`${index}partnerMainActivity`"
                      >
                        <b-form-input
                          :id="`${index}partnerMainActivity`"
                          v-model="partner.partnerMainActivity"
                          :state="errors.length > 0 ? false:null"
                          :name="`${index}partnerMainActivity`"
                          :readonly="!canEdit"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- partnerPosition -->
                  <b-col
                    lg="4"
                    md="6"
                  >
                    <b-form-group
                      label="Cargo"
                      :label-for="`${index}partnerPosition`"
                    >
                      <v-select
                        :id="`${index}partnerPosition`"
                        v-model="partner.partnerPosition"
                        label="cargo"
                        :options="optionsPositions"
                        :name="`${index}partnerPosition`"
                        :disabled="!canEdit"
                      >
                        <span slot="no-options">
                          Lo sentimos, no hay opciones que coincidan.
                        </span>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <!-- partnerStocks -->
                  <b-col
                    v-if="partner.partnerPosition && partner.partnerPosition.compuestoID==='00010'"
                    lg="2"
                    md="6"
                  >
                    <b-form-group
                      label="Porcentaje de acciones*"
                      :label-for="`${index}partnerStocks`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="porcentaje de acciones"
                        rules="porcentage"
                        :vid="`${index}partnerStocks`"
                      >
                        <b-form-input
                          :id="`${index}partnerStocks`"
                          v-model="partner.partnerStocks"
                          maxlength="3"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="30"
                          :formatter="formatterNumber"
                          :readonly="!canEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- partnerCompanySize -->
                  <!-- <b-col
                    lg="6"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="tamaño de la empresa*"
                      :vid="`${index}partnerCompanySize`"
                    >
                      <b-form-group
                        label="Tamaño de la empresa*"
                        :label-for="`${index}partnerCompanySize`"
                      >
                        <b-form-input
                          :id="`${index}partnerCompanySize`"
                          v-model="partner.partnerCompanySize"
                          :state="errors.length > 0 ? false:null"
                          :name="`${index}partnerCompanySize`"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col> -->
                  <!-- partnerCiiu -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="CIIU"
                      :vid="`${index}partnerCiiu`"
                    >
                      <b-form-group
                        label="CIIU"
                        :label-for="`${index}partnerCiiu`"
                      >
                        <v-select
                          :id="`${index}partnerCiiu`"
                          v-model="partner.partnerCiiu"
                          :state="errors.length > 0 ? false:null"
                          :options="options"
                          :name="`${index}partnerCiiu`"
                          :disabled="!canEdit"
                        >
                          <span slot="no-options">
                            Lo sentimos, no hay opciones que coincidan.
                          </span>
                        </v-select>
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- partnerStreet -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      label="Dirección"
                      :label-for="`${index}partnerStreet`"
                    >
                      <b-form-input
                        :id="`${index}partnerStreet`"
                        v-model="partner.partnerStreet"
                        :name="`${index}partnerStreet`"
                        placeholder="Los Melones 350"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerStreetNumber -->
                  <b-col
                    lg="2"
                    md="2"
                  >
                    <b-form-group
                      label="Número"
                      :label-for="`${index}partnerStreetNumber`"
                    >
                      <b-form-input
                        :id="`${index}partnerStreetNumber`"
                        v-model="partner.partnerStreetNumber"
                        :name="`${index}partnerStreetNumber`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerBlock -->
                  <b-col
                    lg="2"
                    md="2"
                  >
                    <b-form-group
                      label="Manzana"
                      :label-for="`${index}partnerBlock`"
                    >
                      <b-form-input
                        :id="`${index}partnerBlock`"
                        v-model="partner.partnerBlock"
                        :name="`${index}partnerBlock`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerLot -->
                  <b-col
                    lg="2"
                    md="2"
                  >
                    <b-form-group
                      label="Lote"
                      :label-for="`${index}partnerLot`"
                    >
                      <b-form-input
                        :id="`${index}partnerLot`"
                        v-model="partner.partnerLot"
                        :name="`${index}partnerLot`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerApartmentNumber -->
                  <b-col
                    lg="2"
                    md="3"
                  >
                    <b-form-group
                      label="Departamento"
                      :label-for="`${index}partnerApartmentNumber`"
                    >
                      <b-form-input
                        :id="`${index}partnerApartmentNumber`"
                        v-model="partner.partnerApartmentNumber"
                        :name="`${index}partnerApartmentNumber`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerInside -->
                  <b-col
                    lg="2"
                    md="3"
                  >
                    <b-form-group
                      label="Interior"
                      :label-for="`${index}partnerInside`"
                    >
                      <b-form-input
                        :id="`${index}partnerInside`"
                        v-model="partner.partnerInside"
                        :name="`${index}partnerInside`"
                        :readonly="!canEdit"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- partnerCountry -->
                  <b-col
                    lg="2"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="país"
                      :vid="`${index}partnerCountry`"
                    >
                      <b-form-group
                        label="País de residencia*"
                        :label-for="`${index}partnerCountry`"
                      >
                        <b-form-select
                          id="country"
                          v-model="partner.partnerCountry"
                          :options="optionsCountry"
                          text-field="varDescripcion"
                          value-field="varCodigo"
                          :state="errors.length > 0 ? false:null"
                          :name="`${index}partnerCountry`"
                          :disabled="!canEdit"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- partnerReference -->
                  <b-col
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="referencia"
                      :vid="`${index}partnerReference`"
                    >
                      <b-form-group
                        label="Referencia*"
                        :label-for="`${index}partnerReference`"
                      >
                        <b-form-input
                          id="reference"
                          v-model="partner.partnerReference"
                          :state="errors.length > 0 ? false:null"
                          :name="`${index}partnerReference`"
                          :readonly="!canEdit"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- UBIGEO -->
                  <b-col
                    lg="4"
                    md="6"
                  >
                    <!-- ubigee -->
                    <b-form-group
                      label="Ubigeo*"
                      :label-for="`${index}partnerUbigee`"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="ubigeo"
                        rules="min:6|max:6"
                        :vid="`${index}partnerUbigee`"
                      >
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              v-b-tooltip.hover.top="'Ubigeo son las siglas oficiales para Código de Ubicación Geográfica que usan el INEI, el RENIEC y la ONPE para codificar las circunscripciones'"
                              variant="outline-primary"
                              class="btn-icon"
                              :disabled="!canEdit"
                            >
                              <feather-icon
                                size="15"
                                icon="InfoIcon"
                              />
                            </b-button>
                          </b-input-group-prepend>
                          <b-form-input
                            :id="`${index}partnerUbigee`"
                            v-model="partner.partnerUbigee"
                            :formatter="formatterNumber"
                            :state="errors.length > 0 ? false:null"
                            :name="`${index}partnerUbigee`"
                            :readonly="!canEdit"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-row
                      class="justify-content-center m-2"
                    >
                      <b-badge
                        href="/ubigeo"
                        variant="light-primary"
                        target="_blank"
                        style="white-space: pre-wrap;"
                      > Puede encontrar su UBIGEO haciendo click AQUÍ </b-badge>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row
                  class="justify-content-end"
                >
                  <!-- buttons -->
                  <b-col md="6">
                    <b-row>
                      <b-col class="mb-50">
                        <b-row class="justify-content-center">
                          <b-button
                            variant="primary"
                            class="m-1 mt-md-2"
                            :disabled="!canEdit"
                            @click="newPartner(index)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-25"
                            />
                            <span class="align-middle">Añadir nuevo</span>
                          </b-button>
                        </b-row>
                      </b-col>
                      <b-col
                        v-if="partners.length>1"
                        class="mb-50"
                      >
                        <b-row class="justify-content-center">
                          <b-button
                            variant="outline-danger"
                            class="m-1 mt-md-2"
                            :disabled="!canEdit"
                            @click="removePartner(index)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              class="mr-25"
                            />
                            <span class="align-middle">Eliminar</span>
                          </b-button>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-card>
      <!-- submit button -->
      <b-row
        class="justify-content-center my-2"
      >
        <b-col
          sm="6"
          md="3"
        >
          <!-- return button -->
          <b-button
            class="my-1"
            variant="secondary"
            :to="{name:'Location-data'}"
            block
          >
            Volver
          </b-button>
        </b-col>
        <b-col
          sm="6"
          md="3"
        >
          <b-button
            class="my-1"
            variant="primary"
            block
            type="submit"
          >
            {{ canEdit ? "Guardar y avanzar" : "Siguiente" }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BCard, BFormGroup, BFormInput, BFormSelect, BRow, BFormRadioGroup, BCol, BButton, VBTooltip, BInputGroupPrepend, BInputGroup, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ciiuOptions from '@utils/ciiuOptions'
import publicOfficesOptions from '@utils/publicOfficesOptions'
import countriesOptions from '@utils/countriesOptions'
import professionsOptions from '@utils/professions'
import activitiesOptions from '@utils/activities'
import positionsOptions from '@utils/positions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

/* eslint-disable */
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
/* eslint-enable */

export default {
  components: {
    BFormRadioGroup,
    BForm,
    BCard,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BBadge,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    // Max date for year validation
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    const isAdult = new Date(today)
    isAdult.setFullYear(isAdult.getFullYear() - 18)
    maxDate.setFullYear(maxDate.getFullYear() - 18)
    const minDate = new Date(today)
    minDate.setFullYear(minDate.getFullYear() - 100)
    return {
      today,
      isAdult,
      executives: [{
        executiveFullName: '',
        executivePosition: '',
        executiveTypeDocument: null,
        executiveNumberDocument: '',
        executiveTelephone: '',
        executiveEmail: '',
      }],
      partners: [],
      optionsTypeDocument: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: '1', text: 'DNI' },
        { value: '2', text: 'Carnét PNP' },
        { value: '3', text: 'Carnét FFAA' },
        { value: '4', text: 'Carnet Extranjeria' },
        { value: '6', text: 'RUC' },
        { value: '7', text: 'Pasaporte' },
      ],
      optionsPerson: [
        { value: 'naturalPerson', text: 'Persona natural' },
        { value: 'legalPerson', text: 'Persona jurídica' },
      ],
      optionsPublic: [
        { value: 'yes', text: 'Si' },
        { value: 'no', text: 'No' },
      ],
      codeFamiliarPEP: [
        { value: 'A', text: 'A: Padres' },
        { value: 'B', text: 'B: Abuelos' },
        { value: 'C', text: 'C: Hermanos' },
        { value: 'D', text: 'D: Hijos en común y propios' },
        { value: 'E', text: 'E: Nietos' },
      ],
      canEdit: JSON.parse(localStorage.getItem('userData')).canEdit || false,
      options: ciiuOptions,
      optionsPublicOffices: publicOfficesOptions,
      optionsPositions: positionsOptions,
      optionsProfession: professionsOptions,
      optionsActivities: activitiesOptions,
      optionsCountry: countriesOptions,
      // Date max & min date
      maxDate,
      minDate,
      required,
      userType: null,
      // Configs DatePickr
      configDatePickr: {
        allowInput: true,
        altInput: true,
        locale: Spanish,
        altFormat: 'd/m/Y',
      },
    }
  },
  created() {
    this.partners = [{ partnerPersonType: '' }]
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.executives = dataU.adminAspects && dataU.adminAspects.executives.length > 0 ? dataU.adminAspects.executives : this.executives
    this.partners = dataU.adminAspects && dataU.adminAspects.partners.length > 0 ? dataU.adminAspects.partners : this.partners
    this.userType = dataU.type
    if (dataU.type === 0) {
      this.canEdit = true
    }
  },
  methods: {
    newPartner(index) {
      this.partners.splice(index + 1, 0, { partnerPersonType: '' })
    },
    addPartner(index) {
      switch (this.partners[index].partnerPersonType) {
        case 'naturalPerson':
          this.partners.splice(index, 1,
            {
              partnerPersonType: 'naturalPerson',
              partnerIsResident: 'yes',
              partnerPlastname: '',
              partnerMlastname: '',
              partnerNames: '',
              partnerTypeDocument: '',
              partnerNumberDocument: '',
              partnerBorndate: '',
              partnerStocks: '',
              partnerNacionality: '',
              partnerAddress: '',
              partnerStreet: '',
              partnerStreetNumber: '',
              partnerBlock: '',
              partnerLot: '',
              partnerApartmentNumber: '',
              partnerInside: '',
              partnerCountry: '',
              partnerUbigee: '',
              partnerTelephone: '',
              partnerCellphone: '',
              partnerEmail: '',
              partnerPosition: {
                compuestoID: '00010',
                cargo: 'Accionista',
              },
              partnerProfession: {
                compuestoID: '00099',
                profesion: 'PROFESION U OCUPACION NO ESPECIFICADA',
              },
              partnerActivity: {
                compuestoID: '000099',
                ocupacion: 'No declara',
              },
              partnerMainActivity: '',
              partnerIsPEP: 'no',
              partnerPepBusinessName: '',
              partnerPepRuc: '',
              partnerPepPosition: '',
              partnerPepStartDate: '',
              partnerStillPosition: 'no',
              partnerPepEndDate: '',
              partnerPepInstitution: '-',
              partnerPepHasSpouse: 'no',
              partnerPepFamilyPEP: [
                {
                  familiarPEPbond: '',
                  familiarPEPplastname: '',
                  familiarPEPmlastname: '',
                  familiarPEPnames: '',
                  familiarPEPage: '',
                  familiarPEPisPEP: 'no',
                  familiarPEPcountry: '',
                  familiarPEPinstitution: '',
                  familiarPEPoccupation: '',
                },
              ],
              partnerPepFamilySpousePEP: [
                {
                  familiarSpousePEPplastname: '',
                  familiarSpousePEPmlastname: '',
                  familiarSpousePEPnames: '',
                  familiarSpousePEPbornDate: '',
                  familiarSpousePEPtypeDocument: '',
                  familiarSpousePEPnumberDocument: '',
                  familiarSpousePEPisPEP: 'no',
                  familiarSpousePEPcountry: '',
                  familiarSpousePEPinstitution: '',
                  familiarSpousePEPoccupation: '',
                },
              ],
              partnerPepShares: [
                {
                  businessName: '',
                  businessRuc: '',
                  businessPercentage: '',
                },
              ],
            })
          break
        case 'legalPerson':
          this.partners.splice(index, 1,
            {
              partnerPersonType: 'legalPerson',
              partnerBussinessName: '',
              partnerRuc: '',
              partnerStreet: '',
              partnerStreetNumber: '',
              partnerBlock: '',
              partnerLot: '',
              partnerPosition: {
                compuestoID: '00010',
                cargo: 'Accionista',
              },
              partnerStocks: '',
              partnerApartmentNumber: '',
              partnerInside: '',
              partnerCountry: '',
              partnerUbigee: '',
              partnerReference: '',
              partnerTelephone: '',
              partnerCellphone: '',
              partnerEmail: '',
              partnerMainActivity: '',
              // partnerCompanySize: '',
              partnerCiiu: null,
            })
          break
        default:
          break
      }
    },
    addFamiliarPEP(index) {
      this.partners[index].partnerPepFamilyPEP.push({
        familiarPEPbond: '',
        familiarPEPplastname: '',
        familiarPEPmlastname: '',
        familiarPEPnames: '',
        familiarPEPage: '',
        familiarPEPisPEP: 'no',
        familiarPEPcountry: '',
        familiarPEPinstitution: '',
        familiarPEPoccupation: '',
      })
    },
    addFamiliarSpousePEP(index) {
      this.partners[index].partnerPepFamilySpousePEP.push({
        familiarSpousePEPplastname: '',
        familiarSpousePEPmlastname: '',
        familiarSpousePEPnames: '',
        familiarSpousePEPbornDate: '',
        familiarSpousePEPtypeDocument: '',
        familiarSpousePEPnumberDocument: '',
        familiarSpousePEPisPEP: 'no',
        familiarSpousePEPcountry: '',
        familiarSpousePEPinstitution: '',
        familiarSpousePEPoccupation: '',
      })
    },
    addShares(index) {
      this.partners[index].partnerPepShares.push({
        businessName: '',
        businessRuc: '',
        businessPercentage: '',
      })
    },
    removeFamiliarPEP(index, i) {
      this.partners[index].partnerPepFamilyPEP.splice(i, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    removeFamiliarSpousePEP(index, i) {
      this.partners[index].partnerPepFamilySpousePEP.splice(i, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    removeShares(index, i) {
      this.partners[index].partnerPepShares.splice(i, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    removePartner(index) {
      this.partners.splice(index, 1)
    },
    addExecutive(index) {
      this.executives.splice(index + 1, 0,
        {
          executiveFullName: '',
          executivePosition: {
            compuestoID: '00010',
            cargo: 'Accionista',
          },
          executiveTypeDocument: '',
          executiveNumberDocument: '',
          executiveTelephone: '',
          executiveEmail: '',
        })
    },
    removeExecutive(index) {
      this.executives.splice(index, 1)
    },
    formatterRuc(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    formatterDoc(td) {
      return td === '0' ? this.formatterNumber : this.formatterChars
    },
    formatterChars(value) {
      return value.replace(/[^a-zA-Z0-9]/g, '')
    },
    formatterDecimal(value) {
      return value.replace(/[^0-9().]/g, '')
    },
    validationForm() {
      if (this.userType === 1 && !this.canEdit) {
        this.$router.replace('/registro/persona-contacto')
      } else {
        this.$refs.adminAspectsForm.validate().then(success => {
          if (success) {
            useJwt.adminAspects({
              email: JSON.parse(localStorage.getItem('userData')).email,
              ruc: JSON.parse(localStorage.getItem('userData')).ruc,
              adminAspects: {
                executives: this.executives,
                partners: this.partners,
              },
            })
              .then(({ data }) => {
                const user = data.user.value
                localStorage.setItem('userData', JSON.stringify(user))
                store.dispatch(
                  'elementVerticalMenu/setStatusStep',
                  { step: 'Administration-aspects', status: user.adminAspects.completed },
                  { root: true },
                )
                this.$router.replace('/registro/persona-contacto')
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Éxito',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Se ha guardado tu información con éxito.',
                        autoHideDelay: 5000,
                      },
                    })
                  })
              })
              .catch(error => {
                console.log('errores', error)
                this.$refs.adminAspectsForm.setErrors(error.response.data.error)
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .repeater-form {
    transition: .35s height;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
